class ReplacePhoneNumber {
  constructor() {
    this.phoneNumber = document.getElementById('shop_reserve_phone_number');
  }

  replace() {
    this.value = this.value
      .replace(/[^(0-9|０-９)]/g, '')
      .replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248));
  }

  apply() {
    this.phoneNumber.onblur = this.replace;
  }
}

export default () => {
  const replacePhoneNumber = new ReplacePhoneNumber();
  replacePhoneNumber.apply();
};
