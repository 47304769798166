import $ from 'jquery';

export default class ShowMoreButton {
  constructor(buttonSelector, parentSelector) {
    this.button = $(buttonSelector);
    this.parent = parentSelector;
  }

  addEvent() {
    this.button.on('click', (event) => {
      event.preventDefault(); // ページ内遷移をさせない

      const button = $(event.currentTarget);
      const href = button.attr('href');
      const items = $(`li${href}`);

      items.removeAttr('style');
      const wrapper = button.parents(this.parent);
      wrapper.remove();
    });
  }
}
