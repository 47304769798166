import $ from 'jquery';

class ScrollTop {
  constructor() {
    this.el = $('.js-scrollTop');
  }

  addEventListener() {
    this.onClick();
    if (this.el.css('display') === 'none') {
      this.onScroll();
    }
  }

  onClick() {
    this.el.click(() => {
      $('body, html').animate({ scrollTop: 0 }, 200, 'linear');
    });
  }

  onScroll() {
    $(window).scroll(() => {
      if ($(window).scrollTop() > 500) {
        this.el.fadeIn();
      } else {
        this.el.fadeOut();
      }
    });
  }
}

export default () => {
  const scrollTop = new ScrollTop();
  scrollTop.addEventListener();
};
