import * as AutoKana from "vanilla-autokana";

let autoLastNameKana;
let autoFirstNameKana;

export default {
  data() {
    return {
      require: {
        lastName: FormData.require.lastName,
        firstName: FormData.require.firstName,
        lastNameKana: FormData.require.lastNameKana,
        firstNameKana: FormData.require.firstNameKana,
        birthdayYear: FormData.require.birthdayYear,
        birthdayMonth: FormData.require.birthdayMonth,
        birthdayDay: FormData.require.birthdayDay,
        phoneNumber: FormData.require.phoneNumber,
        email: FormData.require.email
      },
      preferredDatetime: {
        preferredDate0: FormData.preferredDatetime.preferredDates[0],
        preferredDate1: FormData.preferredDatetime.preferredDates[1],
        preferredDate2: FormData.preferredDatetime.preferredDates[2],
        preferredTime0: FormData.preferredDatetime.preferredTimes[0],
        preferredTime1: FormData.preferredDatetime.preferredTimes[1],
        preferredTime2: FormData.preferredDatetime.preferredTimes[2]
      },
      counselingDetails: {
        counselingDetail1: FormData.counselingDetails.counselingDetail1,
        counselingDetail2: FormData.counselingDetails.counselingDetail2,
        counselingDetail3: FormData.counselingDetails.counselingDetail3
      },
      defaultClass: {
        lastName: true,
        firstName: true,
        lastNameKana: true,
        firstNameKana: true,
        birthdayYear: true,
        birthdayMonth: true,
        birthdayDay: true,
        phoneNumber: true,
        email: true,
        preferredDate0: true,
        preferredTime0: true
      },
      hokepon: FormData.hokepon
    };
  },
  mounted() {
    autoLastNameKana = AutoKana.bind(
      "#shop_reserve_last_name",
      "#shop_reserve_last_name_kana"
    );
    autoFirstNameKana = AutoKana.bind(
      "#shop_reserve_first_name",
      "#shop_reserve_first_name_kana"
    );
  },
  computed: {
    buttonText: function() {
      if (this.isValid) {
        return this.hokepon ? "この内容で申し込む" : "予約内容を送信する";
      } else {
        return "未入力の必須項目があります";
      }
    },
    isValid: function() {
      return (
        Object.keys(this.require).every(key => this.validation[key]) &&
        this.validationPreferredDatetime0() &&
        this.validationPreferredDatetime(1) &&
        this.validationPreferredDatetime(2)
      );
    },
    validation: function() {
      return {
        lastName: !!this.require.lastName,
        firstName: !!this.require.firstName,
        lastNameKana: !!this.require.lastNameKana.match(/^[ぁ-ん]+$/),
        firstNameKana: !!this.require.firstNameKana.match(/^[ぁ-ん]+$/),
        birthdayYear: !!this.require.birthdayYear,
        birthdayMonth: !!this.require.birthdayMonth,
        birthdayDay: !!this.require.birthdayDay,
        phoneNumber: !!this.require.phoneNumber.match(
          /^[0|０][0-9|０-９]{9,10}$|^[0|０][0-9|０-９]{2,3}[-|−|ー][0-9|０-９]{1,4}[-|−|ー][0-9|０-９]{4}$/
        ),
        email: !!this.require.email.match(
          /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
        )
      };
    }
  },
  methods: {
    inputLastName: function(e) {
      this.require.lastName = e.currentTarget.value;
      this.require.lastNameKana = autoLastNameKana.getFurigana();
      if (!this.require.lastName) {
        this.require.lastNameKana = "";
      }
      this.inputRequire("lastNameKana");
    },
    inputFirstName: function(e) {
      this.require.firstName = e.currentTarget.value;
      this.require.firstNameKana = autoFirstNameKana.getFurigana();
      if (!this.require.firstName) {
        this.require.firstNameKana = "";
      }
      this.inputRequire("firstNameKana");
    },
    inputLastNameKana: function(e) {
      this.require.lastNameKana = e.currentTarget.value;
    },
    inputFirstNameKana: function(e) {
      this.require.firstNameKana = e.currentTarget.value;
    },
    formClass: function(data) {
      this.inputRequire(data);
      return {
        [this.validation[data]
          ? "valid"
          : this.invalidOrDefaultClass(data)]: true
      };
    },
    invalidOrDefaultClass: function(data) {
      return this.defaultClass[data] && !this.require[data]
        ? "default"
        : "invalid";
    },
    inputRequire: function(key) {
      if (this.defaultClass[key] && !!this.require[key]) {
        this.defaultClass[key] = false;
      }
    },
    validationPreferredDatetime0: function() {
      if (this.hokepon) {
        return this.validationPreferredDatetime(0);
      } else {
        return (
          this.preferredDatetime.preferredDate0 &&
          this.preferredDatetime.preferredTime0
        );
      }
    },
    validationPreferredDatetime: function(index) {
      return (
        (!this.preferredDatetime[`preferredDate${index}`] &&
          !this.preferredDatetime[`preferredTime${index}`]) ||
        (!!this.preferredDatetime[`preferredDate${index}`] &&
          !!this.preferredDatetime[`preferredTime${index}`])
      );
    },
    isRequiredPreferredType: function(preferredType, index) {
      let type =
        preferredType == "preferredDate" ? "preferredTime" : "preferredDate";
      return index == 0 || !!this.preferredDatetime[`${type}${index}`];
    },
    preferredTypeClass: function(preferredType, index) {
      if (index == 0 && !this.hokepon) {
        this.inputRequirePreferredType(preferredType);
      }

      return {
        [!!this.preferredDatetime[`${preferredType}${index}`]
          ? "valid"
          : this.invalidOrDefaultClassPreferredType(preferredType, index)]: true
      };
    },
    inputRequirePreferredType: function(preferredType) {
      if (
        this.defaultClass[`${preferredType}0`] &&
        !!this.preferredDatetime[`${preferredType}0`]
      ) {
        this.defaultClass[`${preferredType}0`] = false;
      }
    },
    invalidOrDefaultClassPreferredType: function(preferredType, index) {
      return this.isDefaultClassPreferredType0(preferredType, index)
        ? "default"
        : "invalid";
    },
    isDefaultClassPreferredType0: function(preferredType, index) {
      return index != 0
        ? !this.preferredDatetime[`preferredDate${index}`] &&
            !this.preferredDatetime[`preferredTime${index}`]
        : this.defaultClass[`${preferredType}0`] &&
            !this.preferredDatetime.preferredDate0 &&
            !this.preferredDatetime.preferredTime0;
    },
    counselingDetailClass: function(index) {
      return { selected: this.counselingDetails[`counselingDetail${index}`] };
    }
  }
};
