export default () => {
  const shopInfoText = document.getElementById("shop-info-text");
  const text = document.getElementsByClassName("shop-info-text")[0];

  shopInfoText.addEventListener(
    "click",
    () => {
      const shopInfo = document.getElementById("shop-info");
      shopInfoText.innerText =
        shopInfoText.innerText === "詳細を見る" ? "閉じる" : "詳細を見る";
      shopInfo.classList.toggle("show");
      text.classList.toggle("show");
    },
    true
  );
};
