class Beforeunload {
  constructor(formId, varient) {
    this.varient = varient;
    this.form = document.getElementById(formId);
    this.topLinks = Array.prototype.slice
      .call(document.getElementsByTagName("a"))
      .filter(element => element.pathname === "/");
    this.onBeforeunloadHandler = e => {
      e.preventDefault();
      e.returnValue = "このページを離れますか？";
    };
  }

  submitForm() {
    if (this.form) {
      this.form.submit();
    }
  }

  rewriteHref() {
    for (let i = 0; i < this.topLinks.length; i += 1) {
      this.topLinks[i].href = "#";
    }
  }

  apply(e) {
    if (e.target.classList.contains("submit-element")) {
      this.submitForm();
    }
  }

  // sp は、preventDefault, returnValue が効かなかった
  applySP(e) {
    this.apply(e);
    if (
      e.target.parentElement != null &&
      e.target.parentElement.href &&
      e.target.parentElement.href.slice(-1) === "#"
    ) {
      if (window.confirm("このページを離れますか？")) {
        window.location.href = "/";
      }
    }
  }

  applyPC(e) {
    if (
      e.target.classList.contains("phone-element") ||
      e.target.classList.contains("submit-element")
    ) {
      window.removeEventListener("beforeunload", this.onBeforeunloadHandler, {
        passive: false
      });
      this.apply(e);
    }
  }

  clickEvent() {
    window.addEventListener(
      "click",
      e => {
        if (this.varient === "sp") {
          this.applySP(e);
        } else {
          window.addEventListener("beforeunload", this.onBeforeunloadHandler, {
            passive: false
          });
          this.applyPC(e);
        }
      },
      false
    );
  }
}

export default (formId, varient) => {
  const beforeunload = new Beforeunload(formId, varient);
  if (varient === "sp") {
    beforeunload.rewriteHref();
  }
  beforeunload.clickEvent();
};
