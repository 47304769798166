import sideBar from './SideBar';
import smoothScroll from '../../../common/SmoothScroll';
import scrollTop from '../../../common/ScrollTop';
import scrollBanner from '../../../common/ScrollBanner';
import modal from '../../../common/Modal';

export default {
  mixins: [modal],
  mounted: function() {
    sideBar();
    smoothScroll();
    scrollTop();
    scrollBanner();
  }
}
