import ShowMore from './ShowMore';
import orderSelect from './OrderSelect';
import starRating from '../../../common/StarRating';

export default {
  mounted: function() {
    const showMore = new ShowMore();
    showMore.addEvent();

    orderSelect();
    starRating();
  }
}
