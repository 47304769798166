import $ from 'jquery';
import __ from 'underscore';

class StarRating {
  constructor(element) {
    this.target = $(element);
    this.score = this.target.data('score') || 0;
    this.size = this.target.data('size') || 36;

    this.setWrapper();

    this.starCount = 0;
  }

  setTarget() {
    this.target.css({
      height: `${this.size}px`,
    });
  }

  setWrapper() {
    this.wrapper = $('<div></div>', {
      addClass: 'starRating_wrapper',
      css: {
        width: `${this.size * 5}px`,
        'background-size': `${this.size}px ${this.size}px`,
      },
    });
    this.target.prepend(this.wrapper);
  }

  append(element) {
    if (this.starCount < 5) {
      this.wrapper.append(element);
      this.starCount += 1;
    }
  }

  starElement(width) {
    return $('<i></i>', {
      addClass: 'starRating_icon',
      css: {
        width: `${width}%`,
        'background-size': `${this.size}px ${this.size}px`,
      },
    });
  }

  exec() {
    const fullStarCount = Math.floor(this.score); // 塗りつぶす星の数
    const chipStarScore = this.score - fullStarCount; // 欠けた星のスコア

    __(fullStarCount).times(() => {
      const element = this.starElement(20 * 1);
      this.append(element);
    });

    if (fullStarCount < 5) {
      const element = this.starElement(20 * chipStarScore);
      this.append(element);
    }
    this.setTarget();
  }
}

export default () => {
  $('.starRating').each((_index, element) => {
    const starRating = new StarRating(element);
    starRating.exec();
  });
};
