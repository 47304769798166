class Readmore {
  constructor() {
    this.readmores = document.getElementsByClassName("readmore");
    this.readmoreAreas = document.getElementsByClassName("readmoreArea");
  }

  addHiddenClass() {
    this.readmoreAreas.forEach(function(readmoreArea) {
      readmoreArea.classList.add("hidden");
    });
  }

  addOpenEvent(readmoreAreas) {
    this.readmores.forEach(function(readmore, index) {
      readmore.addEventListener("click", e => {
        e.preventDefault();
        readmore.classList.add("hidden");
        readmoreAreas[index].classList.remove("hidden");
      });
    });
  }
}

export default () => {
  const readmore = new Readmore();
  readmore.addHiddenClass();
  readmore.addOpenEvent(readmore.readmoreAreas);
};
