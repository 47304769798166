export default {
  data() {
    return {
      otherReserveDatetime: ""
    };
  },
  mounted() {
    this.otherReserveDatetime = this.isOtherReserveDatetime();
  },
  computed: {
    reserveDatetimeText: function() {
      return !this.otherReserveDatetime;
    }
  },
  methods: {
    isOtherReserveDatetime: function() {
      return (
        !!this.preferredDatetime.preferredDate0 ||
        !!this.preferredDatetime.preferredTime0 ||
        !!this.preferredDatetime.preferredDate1 ||
        !!this.preferredDatetime.preferredTime1
      );
    },
    reserveDatetimeClass: function(index) {
      return { show: index || this.otherReserveDatetime, "u-pT0": !index };
    },
    openReserveDatetimes: function() {
      this.otherReserveDatetime = true;
    },
    closeReserveDatetimes: function() {
      this.otherReserveDatetime = false;
      this.preferredDatetime.preferredDate1 = "";
      this.preferredDatetime.preferredTime1 = "";
      this.preferredDatetime.preferredDate2 = "";
      this.preferredDatetime.preferredTime2 = "";
    }
  }
};
