import beforeunload from "../../../common/Beforeunload";
import shopInfo from "../../../common/ShopInfo";
import toggleReserveDatetimes from "./ToggleReserveDatetimes";
import replacePhoneNumber from "../../../common/ReplacePhoneNumber";
import addBirthdayYearOptions from "../../../common/AddBirthdayYearOptions";
import submitButton from "./SubmitButton";
import applyPhoneNumbers from "../../../common/ApplyPhoneNumbers";

export default {
  mixins: [submitButton, toggleReserveDatetimes, applyPhoneNumbers],
  mounted: function() {
    beforeunload("new_shop_reserve", "pc");
    shopInfo();
    replacePhoneNumber();
    addBirthdayYearOptions();
  }
};
