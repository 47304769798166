import $ from 'jquery';

export default () => {
  $(window).on('scroll', () => {
    const doch = $(document).innerHeight();
    const winh = $(window).innerHeight();
    const bottom = doch - winh;
    if (bottom - 100 <= $(window).scrollTop()) {
      $('.c-bottomBanner').addClass('c-bottomBanner--hide');
    } else {
      $('.c-bottomBanner').removeClass('c-bottomBanner--hide');
    }
  });
};
