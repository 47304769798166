import __ from 'underscore';
import $ from 'jquery';

class ShowKarteButton {
  constructor(button) {
    this.button = $(button);
    this.targetId = this.button.attr('href');
    this.karte = $(this.targetId);
  }

  addEvent() {
    this.button.on('click', (event) => {
      event.preventDefault();
      this.toggleKarte();
    });
  }

  toggleKarte() {
    const karteClass = this.karte.attr('class').split(' ');
    if (__.includes(karteClass, 'disable')) {
      this.karte.removeClass('disable');
      this.button.text('ー');
    } else {
      this.karte.addClass('disable');
      this.button.text('＋');
    }
  }
}

export default () => {
  $('.showKarte').each((_index, element) => {
    const showKarteButton = new ShowKarteButton(element);
    showKarteButton.addEvent();
  });
};
