export default {
  data: function() {
    return {
      kv_location: true
    };
  },
  methods: {
    changeKv: function(e) {
      e.preventDefault();
      this.kv_location = !this.kv_location;
    }
  }
};
