export default {
  computed: {
    phoneNumberText: function() {
      return this.isTel() ? "0120-206-820" : "0120-206-820";
    },
    phoneNumberHref: function() {
      return this.isTel() ? "tel:0120-206-820" : "tel:0120-206-820";
    }
  },
  methods: {
    isTel: function() {
      return document.cookie
        .split("; ")
        .filter(item => item.includes("tel=true")).length;
    }
  }
};
