import $ from 'jquery';

export default {
  data: function() {
    return {
      modalOverlay: false,
      modals: {},
    }
  },
  mounted: function() {
    $('.openModal').each((_index, element) => {
      let id = $(element).attr('href');
      this.$set(this.modals, id, false);
    });
  },
  computed: {
    modalOverlayClass: function() {
      return this.modalOverlay ? 'active' : '';
    },
  },
  methods: {
    handleClickCloseModal: function() {
      for(let key in this.modals) {
        this.$set(this.modals, key, false);
      }
      this.modalOverlay = false;
    },
    handleClickOpenModal: function(e) {
      e.preventDefault();
      const link = $(e.currentTarget);
      let id = link.attr('href');
      if (typeof id === 'undefined') {
        id = link.data('link');
      }
      this._openModal(id);
    },
    modalClass: function(id) {
      return this.modals[id] ? 'active' : '';
    },
    _openModal: function(id) {
      this.$set(this.modals, id, true);
      this.modalOverlay = true;
    }
  }
}
