import AreasShow from "./AreasShow/index";
import BrandsCitiesShow from "./BrandsCitiesShow";
import BrandsIndex from "./BrandsIndex/index";
import BrandsPrefecturesShow from "./BrandsPrefecturesShow";
import BrandsShow from "./BrandsShow/index";
import CitiesShow from "./CitiesShow/index";
import CompaniesCitiesShow from "./CompaniesCitiesShow";
import CompaniesIndex from "./CompaniesIndex/index";
import CompaniesPrefecturesShow from "./CompaniesPrefecturesShow";
import CompaniesShow from "./CompaniesShow";
import LinesShow from "./LinesShow/index";
import PrefecturesShow from "./PrefecturesShow/index";
import ShopsShow from "./ShopsShow/index";
import StationGroupsShow from "./StationGroupsShow/index";
import TopIndex from "./TopIndex/index";
import Shared from "./shared/index";
import ShopsReservesNew from "./ShopsReservesNew/index";
import ShopsReviewsNew from "./ShopsReviewsNew/index";
import ShopsSearchShopReviewsNew from "./ShopsSearchShopReviewsNew/index";
import FpIndex from "./FpIndex/index";

import Vue from "vue";

const PAGE_FUNCTIONS = {
  AreasShow,
  BrandsCitiesShow,
  BrandsIndex,
  BrandsPrefecturesShow,
  BrandsShow,
  CitiesShow,
  CompaniesCitiesShow,
  CompaniesIndex,
  CompaniesPrefecturesShow,
  CompaniesShow,
  LinesShow,
  PrefecturesShow,
  ShopsShow,
  StationGroupsShow,
  TopIndex,
  ShopsReservesNew,
  ShopsReservesCreate: ShopsReservesNew,
  ShopsReviewsNew,
  ShopsReviewsCreate: ShopsReviewsNew,
  ShopsSearchShopReviewsNew,
  ShopsSearchShopReviewsCreate: ShopsSearchShopReviewsNew,
  FpIndex
};

Vue.mixin(Shared);

export default () => {
  const el = document.getElementById("app");
  const namespace = el.getAttribute("data-namespace");

  if (PAGE_FUNCTIONS[namespace]) {
    window.$vm = new Vue(PAGE_FUNCTIONS[namespace]).$mount(el);
  } else {
    window.$vm = new Vue().$mount(el);
  }
};
