import Choices from "choices.js";

export default id => {
  const selects = document.getElementById(id);
  if (selects != null) {
    new Choices(selects, {
      // eslint-disable-line
      searchPlaceholderValue: "キーワードで絞り込む（例：新宿区）",
      noResultsText: "該当する店舗がありません",
      itemSelectText: "選択",
      searchResultLimit: 100
    });
  }
};
