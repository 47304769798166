import $ from 'jquery';

class SideBar {
  constructor(element) {
    this.element = $(element);
    this.list = $(this.element.find('.l-side_list'));
    this.items = this.list.children('.l-side_listItem');
    this.limit = this.list.data('limit');
  }

  foldItems() {
    this.items.each((index, item) => {
      if (index >= this.limit) {
        $(item).css('display', 'none');
      }
    });
  }

  unfoldItems() {
    this.items.each((index, item) => $(item).css('display', 'block'));
  }

  appendButton() {
    this.button = $('<button name="button" type="button" class="c-link l-side_button">さらに表示する</button>');
    this.element.append(this.button);
    this.button.on('click', (e) => {
      e.preventDefault();
      this.unfoldItems();
      e.currentTarget.remove();
    });
  }

  create() {
    if (this.limit > 0) {
      this.foldItems();
      if (this.limit !== this.items.length) {
        this.appendButton();
      }
    }
  }
}

export default () => {
  $('.l-side_element').each((index, list) => new SideBar(list).create());
};
