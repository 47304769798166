import scoreChart from "../../../common/ScoreChart";
import ShowMoreButton from "../../../common/ShowMoreButton";
import starRating from "../../../common/StarRating";
import showKarte from "./ShowKarte";
import applyPhoneNumbers from "../../../common/ApplyPhoneNumbers";

export default {
  mixins: [applyPhoneNumbers],
  mounted: function() {
    starRating();
    showKarte();

    const showMoreButton = new ShowMoreButton(
      "#showMoreButton",
      "#buttonWrapper"
    );
    showMoreButton.addEvent();
    scoreChart();
  }
};
