import $ from 'jquery';

class AnkerLink {
  constructor(varient) {
    this.link = $('a.ankerlink');
    this.header = $('header');
    this.margin = varient === 'sp' && this.header ? this.header.height() + 10 : 0; // 10は微調整・spのheader分調整marginを設定
  }

  smoothScroll() {
    this.link.each((index, link) => {
      $(link).on('click', (e) => {
        const href = $(e.currentTarget).attr('href');
        const target = $(href);
        const position = target.offset().top - this.margin;
        $('html, body').animate({ scrollTop: position }, 400, 'swing');
      });
    });
  }
}

export default (varient = 'pc') => {
  const ankerLink = new AnkerLink(varient);
  ankerLink.smoothScroll();
};
