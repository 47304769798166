export default {
  mounted: function() {
    if (!$('.js-form-have_before_insurance')[0].checked) {
      $('.js-row-before_insurance').hide();
    }
    $('.js-form-have_before_insurance').change((e) => {
      if ($(e.target).data().value) {
        $('.js-row-before_insurance').slideDown();
      } else {
        $('.js-row-before_insurance').slideUp();
        $('.js-row-before_insurance .c-checkbox input').prop('checked', false);
      }
    });
  }
}
