import Chart from "chart.js";
import __ from "underscore";
import $ from "jquery";

class ScoreChart {
  constructor() {
    this.canvas = document.getElementById("scoreChart");
    this.ctx = this.canvas.getContext("2d");
    this.datamap = {
      店内の雰囲気: $("#chart_atmosphere").data("score"),
      スタッフの接客: $("#chart_staff").data("score"),
      提案内容: $("#chart_proposal").data("score"),
      アクセス: $("#chart_access").data("score"),
      "混雑状況・待ち時間": $("#chart_congestion").data("score")
    };

    this.options = {
      layout: {
        padding: {
          top: 5,
          bottom: 2
        }
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      scale: {
        pointLabels: {
          display: false,
          fontColor: "#ffffff",
          fontSize: 13
        },
        gridLines: {
          color: "#e2e2e2",
          circular: true
        },
        angleLines: {
          color: "#e2e2e2"
        },
        ticks: {
          display: false,
          beginAtZero: true,
          stepSize: 1,
          max: 5
        }
      }
    };
  }

  config() {
    return {
      type: "radar",
      data: this.data(),
      options: this.options
    };
  }

  data() {
    return {
      // ラベルを設定しているが、改行がうまくいかないため非表示にしてある
      labels: __.keys(this.datamap),
      datasets: [
        {
          data: __.values(this.datamap),
          backgroundColor: "rgba(109, 192, 126, 0.3)",
          borderWidth: "0px",
          pointStyle: "circle",
          pointRadius: 4,
          pointBackgroundColor: "#41a368",
          pointBorderColor: "#ffffff"
        }
      ]
    };
  }

  create() {
    return new Chart(this.ctx, this.config());
  }
}

export default () => {
  if (document.getElementById("scoreChart")) {
    new ScoreChart().create();
  }
};
