class AddBirthdayYearOptions {
  constructor() {
    this.birthdayYear = document.getElementById("shop_reserve_birthday_1i");
  }

  fixSelected() {
    if (
      this.birthdayYear.selectedIndex === 0 &&
      this.birthdayYear[0].innerHTML === "-"
    ) {
      this.birthdayYear.insertBefore(
        this.birthdayYear[0],
        this.birthdayYear.options[71]
      );
      this.birthdayYear.setCustomValidity("true");
    } else {
      this.birthdayYear[0].remove();
      this.birthdayYear.setCustomValidity("");
    }
  }

  apply() {
    this.fixSelected();
    this.birthdayYear.addEventListener("change", () => {
      if (this.birthdayYear.selectedIndex === 70) {
        this.birthdayYear.setCustomValidity("true");
      } else {
        this.birthdayYear.setCustomValidity("");
      }
    });
  }
}

export default () => {
  const addBirthdayYearOptions = new AddBirthdayYearOptions();
  addBirthdayYearOptions.apply();
};
