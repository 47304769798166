import $ from 'jquery';

export default class ShowMore {
  constructor() {
    this.button = $('.showmore');
  }

  addEvent() {
    this.button.on('click', (event) => {
      event.preventDefault(); // ページ内遷移をさせない

      const button = $(event.currentTarget);
      const href = button.attr('href');
      const items = $(`ul${href}`).children();

      items.removeAttr('style');
      button.parents('.c-sectionBox').removeClass('u-mB80');
      button.parent('.pp-button_wrapper').remove();
    });
  }
}
