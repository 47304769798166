import $ from 'jquery';

class OrderSelect {
  constructor() {
    this.select = $('.orderSelect');
    this.form = $('.orderForm');
  }

  addEvent() {
    this.select.on('change', () => {
      this.form.submit();
    });
  }
}

export default () => {
  const orderSelect = new OrderSelect();
  orderSelect.addEvent();
};
